<template>
  <div class="search">
    <div class="switch">
      <div
        v-for="(item, index) of searchTypeData"
        :key="index"
        :class="{ active: searchInfo.type === item.type, uiText: index < 2 }"
        @click="changeSearchType(item, index)"
      >
        {{ $t(item.name) }}
      </div>
    </div>
    <div class="container">
      <div class="country">
        <el-select
          v-model="searchInfo.country"
          :placeholder="$t('8c7aeca')"
          class="selectContainer"
          @change="changeProvince"
        >
          <el-option
            v-for="item in countryList"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          />
        </el-select>
      </div>
      <div class="context">
        <input
          v-model="searchInfo.keyword"
          class="searchInput"
          type="text"
          :placeholder="placeholder"
        />
        <span class="inputPrefix">
          <i class="el-input__icon el-icon-search"></i>
        </span>
      </div>
      <div class="searchButtom" type="primary" @click="search">{{ $t('3a3636a') }}</div>
      <el-popover
        ref="searchPopover"
        v-model="showPopover"
        placement="bottom-end"
        width="1140"
        :offset="offset"
        trigger="manual"
      >
        <LawsForm
          v-if="searchInfo.type === 'LAWS'"
          :search-info="searchInfo"
          @search="search"
        />
        <CaseForm
          v-else-if="searchInfo.type === 'CASE'"
          :search-info="searchInfo"
          @search="search"
        />
        <div slot="reference" class="applyQuery" @click="onChange">{{ $t('593cea5') }}</div>
      </el-popover>

      <div class="applyQuery" @click="applyQuery">{{ $t('5dc734e') }}</div>
    </div>
    <!--    <div class="result">-->
    <!--      <div class="des">相关结果:</div>-->
    <!--      <div class="uiText">行贿</div>-->
    <!--      <div class="uiText">刑事案件</div>-->
    <!--      <div class="uiText">档案</div>-->
    <!--      <div class="uiText">受贿</div>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { COUNTRY_TYPE, toList } from '@/utils/constants'
import LawsForm from './LawsForm'
import CaseForm from './CaseForm'
export default {
  name: 'Search',
  components: {
    LawsForm,
    CaseForm,
  },
  props: {
    searchInfo: {
      type: Object,
      required: true,
    },
    countryList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showPopover: false,
      offset: 95,
      searchTypeData: [
        {
          name: '78914b1',
          type: 'LAWS',
        },
        {
          name: 'ce86c88',
          type: 'CASE',
        },
        {
          name: '企业查询',
          type: 'INFO',
        },
      ],
      country: '',
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    placeholder() {
      let result = '902c739'
      if (this.searchInfo.type === 'CASE') {
        result = 'b65ea5a'
      }
      return this.$t(result)
    },
  },
  watch: {

  },
  mounted() {
    // this.getData()
  },
  methods: {
    onChange() {
      if (this.userInfo.userType) {
        this.showPopover = !this.showPopover
      } else {
        this.$router.push('/account/login')
      }
    },
    getData() {
      // let type = this.searchInfo.type
      // if (type === 'CASE') {
      //   type = 'JUDICIAL_CASE'
      // } else if (type === 'LAWS') {
      //   type = 'LAW_REGULATIONS'
      // } else {
      //   type = ''
      // }
      // if (type) {
      //   this.getDictionaryList({
      //     type: type,
      //   })
      // }
    },
    search(data = {}) {
      this.$refs.searchPopover.doClose()
      this.$emit('search', data)
    },
    applyQuery() {
      this.$router.push({
        path: '/legal/apply',
      })
    },
    changeSearchType(item, index) {
      this.searchInfo.keyword = ''
      this.searchInfo.type = item.type
      if (item.type === 'INFO') {
        this.$router.push({
          path: '/legal/info'
        })
      } else {
        this.$router.replace(`/legal/query?type=${item.type}`)
      }

      // window.location =
    },
    changeProvince() {
      this.$emit('search')
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.search {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;
  height: 188px;
  margin: 20px 0;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
  .switch {
    display: flex;
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 400;
    color: @uni-text-color-select;
    .uiText {
      margin-right: 20px;
    }
    div {
      padding: 10px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
    }
    .active {
      position: relative;
      background: @uni-text-color-select;
      color: #fff;
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -12px;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: #cccccc;
      }
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -13px;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: #fff;
        z-index: 1;
      }
    }
  }
  .container {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .country {
    .selectContainer {
      & /deep/ .el-input__inner {
        height: 48px;
        border: 1px solid #ccc;
        border-right: 1px solid #ddd;
        border-radius: 4px 0 0 4px;
      }
    }
  }
  .context {
    position: relative;
    width: 100%;
  }
  .searchInput {
    .uni-input;
    width: 100%;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0 4px 4px 0;
  }
  .inputPrefix {
    position: absolute;
    top: 0;
    left: 5px;
    transition: all 0.3s;
    height: 100%;
    color: #bdbdbd;
    text-align: center;
  }
  .searchButtom {
    width: 118px;
    height: 48px;
    margin-left: 10px;
    background: #00a4ff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
  }
  .applyQuery {
    white-space: nowrap;
    margin-left: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #00a4ff;
    cursor: pointer;
  }
  .result {
    align-self: start;
    display: flex;
    margin-top: 16px;
    font-size: 12px;
    color: #333333;
    .des {
    }
    .uiText {
      margin-left: 20px;
      color: #666666;
    }
  }
}
</style>
