<template>
  <el-form
    ref="releaseForm"
    :model="releaseForm"
    :rules="rules"
    class="releaseForm"
    label-width="82px"
  >
    <el-row :gutter="60">
      <el-col :span="12">
        <el-form-item :label="autoPrefix('timeLimit1')" prop="timeLimit1">
          <el-date-picker
            v-model="releaseForm.timeLimit1"
            :picker-options="pickerOptions"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="~"
            :start-placeholder="autoPrefix('startPla', 'pla')"
            :end-placeholder="autoPrefix('endPla', 'pla')"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="autoPrefix('timeLimit2')" prop="timeLimit2">
          <el-date-picker
            v-model="releaseForm.timeLimit2"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="~"
            :start-placeholder="autoPrefix('startPla', 'pla')"
            :end-placeholder="autoPrefix('endPla', 'pla')"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="autoPrefix('title')" prop="title">
          <el-input
            v-model="releaseForm.title"
            :placeholder="autoPrefix('title', 'pla')"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="autoPrefix('content')" prop="content">
          <el-input
            v-model="releaseForm.content"
            :placeholder="autoPrefix('content', 'pla')"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12" :offset="6">
        <el-form-item class="buttonCntainer">
          <el-button
            class="release"
            type="primary"
            @click="submitForm('releaseForm')"
          >
            检 索
          </el-button>
          <el-button class="cancel" @click="cancelSubmitForm('releaseForm')">
            重 置
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: 'LawsForm',
  props: {
    searchInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      releaseForm: {
        timeLimit1: [],
        timeLimit2: [],
        title: '',
        content: '',
      },
      pickerOptions: {
        disabledDate(date) {
          const today = new Date()
          today.setHours(23)
          return date > today
        },
      },
      fileList: [],
      label: {
        timeLimit1: '发布时间',
        timeLimit2: '实施时间',
        title: '法规标题',
        content: '正文检索',
      },
      pla: {
        startPla: '开始时间',
        endPla: '结束时间',
        title: '请输入法律法规标题',
        content: '请输入法律法规正文',
      },
      rules: {},
      businessTypeList: [],
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    autoPrefix(name, key = 'label') {
      // const str = i18nPrefix[key] + name
      // return this.$t(str)
      let str = this[key][name]
      if (key === 'label') {
        str += '：'
      }
      return str
    },
    cancelSubmitForm() {
      this.releaseForm = {
        timeLimit1: [],
        timeLimit2: [],
        title: '',
        content: '',
      }
    },
    handleForm() {
      const form = this.releaseForm
      const cod =
        form.title ||
        form.content ||
        form.timeLimit1.length ||
        form.timeLimit2.length
      if (cod) {
        const data = {
          title: form.title,
          content: form.content,
          issue_date_start: form.timeLimit1[0],
          issue_date_end: form.timeLimit1[1],
          implementation_date_start: form.timeLimit2[0],
          implementation_date_end: form.timeLimit2[1],
        }
        this.$emit('search', data)
      } else {
        this.$message.error('请输入检索条件！')
      }
    },
    submitForm() {
      this.handleForm()
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.releaseForm {
  // margin: 0 auto;
  padding: 38px 18px 18px;
  & /deep/ .el-form-item {
    margin-bottom: 24px;
  }
  & /deep/ .el-input__inner {
    width: 440px;
  }
  .buttonCntainer {
    margin-bottom: 0;
    & /deep/ .el-form-item__content {
      display: flex;
      justify-content: space-between;
    }

    .cancel {
      width: 160px;
    }
    .release {
      width: 160px;
    }
  }
}
</style>
