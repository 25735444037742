<template>
  <el-form
    ref="releaseForm"
    :model="releaseForm"
    :rules="rules"
    class="releaseForm"
    label-width="82px"
  >
    <el-row :gutter="60">
      <el-col :span="12">
        <el-form-item :label="autoPrefix('context')" prop="context">
          <el-input
            v-model="releaseForm.context"
            :placeholder="autoPrefix('context', 'pla')"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="autoPrefix('caseReason')" prop="caseReason">
          <el-select
            v-model="releaseForm.caseReason"
            clearable
            :placeholder="autoPrefix('caseReason', 'pla')"
          >
            <el-option
              v-for="item of caseReasonList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="autoPrefix('title')" prop="title">
          <el-input
            v-model="releaseForm.title"
            :placeholder="autoPrefix('title', 'pla')"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="autoPrefix('caseCode')" prop="caseCode">
          <el-input
            v-model="releaseForm.caseCode"
            :placeholder="autoPrefix('caseCode', 'pla')"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="autoPrefix('court')" prop="court">
          <el-input
            v-model="releaseForm.court"
            :placeholder="autoPrefix('court', 'pla')"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="autoPrefix('judgeProcess')" prop="judgeProcess">
          <el-select
            v-model="releaseForm.judgeProcess"
            :placeholder="autoPrefix('judgeProcess', 'pla')"
            :disabled="releaseForm.caseType.length <= 0"
            clearable
            @click.native="clickJudgeProcess"
          >
            <el-option
              v-for="item of judgeProcessList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="autoPrefix('caseType')" prop="caseType">
          <el-select
            v-model="releaseForm.caseType"
            clearable
            filterable
            :placeholder="autoPrefix('caseType', 'pla')"
          >
            <el-option
              v-for="item of caseTypeList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="autoPrefix('docType')" prop="docType">
          <el-select
            v-model="releaseForm.docType"
            clearable
            :placeholder="autoPrefix('docType', 'pla')"
          >
            <el-option
              v-for="item in docTypeList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="autoPrefix('timeLimit')" prop="timeLimit">
          <el-date-picker
            v-model="releaseForm.timeLimit"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="~"
            :start-placeholder="autoPrefix('startPla', 'pla')"
            :end-placeholder="autoPrefix('endPla', 'pla')"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item :label="autoPrefix('litigant')" prop="litigant">
          <el-input
            v-model="releaseForm.litigant"
            :placeholder="autoPrefix('litigant', 'pla')"
          />
        </el-form-item>
      </el-col>
      <el-col :span="12" :offset="6">
        <el-form-item class="buttonCntainer">
          <el-button
            class="release"
            type="primary"
            @click="submitForm('releaseForm')"
          >
            检 索
          </el-button>
          <el-button class="cancel" @click="cancelSubmitForm('releaseForm')">
            重 置
          </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import API from '@/api'
export default {
  name: 'CaseForm',
  props: {
    searchInfo: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      releaseForm: {
        context: '',
        caseReason: '',
        title: '',
        caseCode: '',
        court: '',
        judgeProcess: '',
        caseType: '',
        timeLimit: [],
        docType: '',
        litigant: '',
      },
      caseReasonList: [],
      judgeProcessList: [],
      caseTypeList: [],
      docTypeList: [],

      pickerOptions: {
        disabledDate(date) {
          const today = new Date()
          today.setHours(23)
          return date > today
        },
      },
      fileList: [],
      label: {
        context: '全文检索',
        caseReason: '案由',
        title: '案件名称',
        caseCode: '案号',
        court: '法院名称',
        judgeProcess: '审判程序',
        caseType: '案件类型',
        timeLimit: '裁判日期',
        docType: '文书类型',
        litigant: '当事人',
      },
      pla: {
        context: '请输入',
        caseReason: '请选择',
        title: '请输入',
        caseCode: '请输入',
        court: '请输入',
        judgeProcess: '请选择',
        caseType: '请选择',
        timeLimit: '请选择',
        docType: '请选择',
        litigant: '请输入',
        startPla: '开始时间',
        endPla: '结束时间',
      },
      rules: {},
      businessTypeList: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getJudicialCaseSelect('CASE_TYPE', 'caseTypeList')
    this.getJudicialCaseSelect('CASE_REASON', 'caseReasonList')
    this.getJudicialCaseSelect('JUDGE_PROCESS', 'judgeProcessList')
    this.getJudicialCaseSelect('DOC_TYPE', 'docTypeList')
  },
  methods: {
    getJudicialCaseSelect(type, selectType) {
      API.legal.getJudicialCaseSelect({ type }).then(
        (res) => {
          if (selectType === 'caseTypeList') {
            // 过滤一些无效数据
            res.content = res.content.filter((item) => {
              return item.length < 30
            })
          }
          this[selectType] = res.content
        },
        (error) => {
          console.log('error', error)
        }
      )
    },
    clickJudgeProcess() {
      if (!this.releaseForm.caseType) {
        this.$message({
          message: '请先选择案件类型',
          type: 'warning',
        })
      }
    },
    autoPrefix(name, key = 'label') {
      // const str = i18nPrefix[key] + name
      // return this.$t(str)
      let str = this[key][name]
      if (key === 'label') {
        str += '：'
      }
      return str
    },
    cancelSubmitForm() {
      this.releaseForm = {
        context: '',
        caseReason: '',
        title: '',
        caseCode: '',
        court: '',
        judgeProcess: '',
        caseType: '',
        timeLimit: [],
        docType: '',
        litigant: '',
      }
    },
    handleForm() {
      const form = this.releaseForm
      const cod =
        form.context ||
        form.caseReason ||
        form.title ||
        form.caseCode ||
        form.court ||
        form.judgeProcess ||
        form.caseType ||
        form.docType ||
        form.litigant ||
        form.timeLimit.length
      if (cod) {
        const data = {
          context: form.context,
          caseReason: form.caseReason,
          title: form.title,
          caseCode: form.caseCode,
          court: form.court,
          judgeProcess: form.judgeProcess,
          caseType: form.caseType,
          docType: form.docType,
          litigant: form.litigant,
          trialDateStart: form.timeLimit[0],
          trialDateEnd: form.timeLimit[1],
        }
        this.$emit('search', data)
      } else {
        this.$message.error('请输入检索条件！')
      }
    },
    submitForm() {
      this.handleForm()
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.releaseForm {
  // margin: 0 auto;
  padding: 38px 18px 18px;
  & /deep/ .el-form-item {
    margin-bottom: 24px;
  }
  & /deep/ .el-input__inner {
    width: 440px;
  }
  .buttonCntainer {
    margin-bottom: 0;
    & /deep/ .el-form-item__content {
      display: flex;
      justify-content: space-between;
    }

    .cancel {
      width: 160px;
    }
    .release {
      width: 160px;
    }
  }
}
</style>
