<template>
  <div class="lawItem" @click="goDetails">
    <div class="item">
      <div
        v-if="data.country === 'CHINA'"
        class="tag"
        :class="{ active: data.effectiveness !== '失效' }"
      >
        {{ handleTag() }}
      </div>
      <div class="title">
        {{ data.title | textFilter }}
      </div>
      <div class="time">发布时间：{{ issueDate | textFilter }}</div>
    </div>
    <div class="item">
      <div class="des">{{ data.release_department | textFilter }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LawsItme",
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    issueDate() {
      return this.data.country === "UZBEKISTAN" ? "" : this.data.issue_date;
    },
  },
  created() {},
  methods: {
    handleTag() {
      const effectiveness = this.data.effectiveness;
      let result = effectiveness;
      if (effectiveness === "有效") {
        result = "现行有效";
      }
      return result || "失效/废止";
    },
    goDetails() {
      this.$router.push({
        path: "/legal/details",
        query: {
          id: this.data.sc_data_id,
          type: this.type,
          country: this.data.country,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/styles/variables.less";
@import "~@/styles/common.less";
.lawItem {
  margin-left: 30px;
  padding: 30px 10px 30px 0;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  .item {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999999;
    &:last-child {
      margin-top: 20px;
    }
    .tag {
      width: 64px;
      height: 18px;
      background: #e8e8e8;
      border-radius: 2px;
      font-weight: 400;
      color: #c3c3c3;
      line-height: 18px;
      text-align: center;
    }
    .active {
      color: #51c518;
      background: rgba(81, 197, 24, 0.08);
    }
    .title {
      width: 600px;
      margin-left: 8px;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
    }
    .time {
      position: absolute;
      right: 30px;
      font-size: 14px;
      color: #666666;
    }
    .des {
      margin-right: 12px;
    }
  }
  &:hover {
    .title,
    .time {
      color: @uni-text-color-select;
    }
  }
}
</style>
