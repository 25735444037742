<template>
  <div class="container">
    <div v-for="(typeItem, index) of dataList" :key="index" class="item">
      <div class="title">{{ $t(typeItem.name) }}</div>
      <div
        v-for="item of handleItemList(typeItem)"
        :key="item.name"
        :class="{ active: type === item.name }"
        class="des"
        @click="changeItme(typeItem, item)"
      >
        {{ item.name }}{{ `（${item.count}）` }}

        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'Classification',
  components: {},
  props: {
    searchInfo: {
      type: Object,
      required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      searchTypeIndex: 0,
      country: '',
      type: '',
    }
  },
  computed: {
    ...mapGetters(['dictionaryList']),
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions(['getDictionaryList']),
    handleItemList(typeItem) {
      const key = typeItem.key
      const result = (this.data[key] || []).filter(keyItem => {
        let { name } = keyItem || {}
        // trialDate  只展示 1980-2022审判年份的数据
        if (key === 'trialDate' && name) {
          const cod = Number(name) >= 1980 && Number(name) <= 2022
          name = cod
        }
        return name
      })
      if (key === 'trialDate') {
        result.sort((a, b) => (Number(b.name) - Number(a.name)))
      }
      return result
    },
    changeItme(typeItem, item) {
      const key = typeItem.key
      // 暂时用中文搜索
      const name = this.searchInfo[key]
      if (name && item.name) {
        this.searchInfo[key] = ''
        this.type = ''
      } else {
        this.searchInfo[key] = item.name
        this.type = item.name
      }
      this.$emit('search')
      // if (key === 'docType') {
      //   this.$emit('changeTitle', item.name)
      // }
    },
    getData() {
      this.getDictionaryList({
        type: 'COUNTRY',
      })
    },
    search() {
      this.$emit('search')
    },
    applyQuery() {
      // this.$router.push({
      //   path: ''
      // })
    },
    changeSearchType(item, index) {
      this.searchTypeIndex = index
      this.searchInfo.keyword = ''
      this.searchInfo.sourceType = item.type
      this.search()
    },
    changeProvince() {
      this.getProvinceList({ parentId: this.provinceId })
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.container {
  .title {
    position: relative;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
  }

  .des {
    position: relative;
    margin-left: 20px;
    margin-bottom: 24px;
    font-size: 14px;
    color: #666666;
    cursor: pointer;
    &:hover {
      color: @uni-text-color-select;
    }
    &::before {
      position: absolute;
      content: '';
      top: 50%;
      left: -20px;
      width: 1px;
      height: 38px;
      background: #dbdbdb;
      background: #dbdbdb;
    }
    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      left: -20px;
      width: 10px;
      height: 1px;
      background: #dbdbdb;
    }
    &:last-child::before {
      display: none;
    }
  }

  .active {
    color: @uni-text-color-select;
    .line {
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 2px;
      height: 14px;
      background: #00a4ff;
      border-radius: 2px;
    }
  }

  .item {
    margin-bottom: 40px;
  }
}
</style>
