<template>
  <div v-loading="searchLoading" class="legal">
    <Search
      class="search"
      :search-info="searchInfo"
      :country-list="countryList"
      @search="getData"
    />
    <div class="content">
      <div class="lt">
        <Classification
          :data-list="dataList"
          :search-info="searchInfo"
          :data="lawRegulationsList"
          @search="getData"
          @changeTitle="changeTitle"
        />
      </div>
      <div class="rt">
        <Title :title="$t(title)" />
        <div class="rtItmeContainer">
          <Empty
            v-if="lawRegulationsList.pageData.content.length === 0"
            type="serviceContent"
          />
          <component
            :is="componentName"
            v-for="item of lawRegulationsList.pageData.content"
            :key="item.id"
            class="item"
            :data="item"
            :type="searchInfo.type"
            :country="searchInfo.country"
          />
          <Pagination
            v-if="lawRegulationsList.pageData.content.length"
            class="pagination"
            :page-info="pageInfo"
            @changePage="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { translateObj } from "@/utils/helper";
import Title from "@/components/Title";
import Classification from "./components/Classification";
import Search from "./components/Search";
import LAWSItem from "./components/LawsItme";
import Empty from "@/components/Empty/Factory";
import CASEItem from "./components/CaseItme";
import Pagination from "@/components/Pagination";
import API from "@/api";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Legal",
  components: {
    Search,
    Title,
    Classification,
    LAWSItem,
    CASEItem,
    Pagination,
    Empty,
  },
  data() {
    return {
      searchLoading: false,
      title: "法律",
      searchInfo: {
        type: this.$route.query.type ? this.$route.query.type : "LAWS",
        category: "",
        country: "",
        effectiveness: "",
        keyword: "",
        caseReason: "",
        docType: "",
        trialDate: "",
        releaseDepartment: "",
        regulationCategory: "",
        year: "",
      },
      pageInfo: {
        currentPage: 1,
        size: 8,
        total: 1,
      },
      options: [],
      labelData: [],
      dataList: [
        {
          name: "e83c923",
          key: "category",
        },
        {
          name: "legalQuery.leftNavBar[0]",
          key: "releaseDepartment",
        },
        {
          name: "ebd8120",
          key: "effectiveness",
        },
        {
          name: "legalQuery.leftNavBar[1]",
          key: "regulationCategory",
        },
        {
          name: "legalQuery.leftNavBar[2]",
          key: "year",
        },
      ],
      searchData: {},
      countryList: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.changeLabelData();
    });
  },
  computed: {
    ...mapGetters(["lawRegulationsList", "dictionaryList"]),
    componentName() {
      return this.searchInfo.type + "Item";
    },
  },
  watch: {
    lawRegulationsList(newVal) {
      if (newVal) {
        this.searchLoading = false;
        const pageData = newVal.pageData;
        if (pageData) {
          let total = pageData.page.total;
          // if (total > 10000) {
          //   total = 10000;
          // }
          this.pageInfo.total = total;
          this.pageInfo.size = pageData.page.size;
        }
      }
    },
    "searchInfo.type"(newVal) {
      if (newVal) {
        this.searchData = {};
        this.handleSearchInfoTypeChange(newVal);
        this.getData();
      }
    },
    // 'searchInfo.docType'(newVal) {
    //   if (newVal) {
    //     this.changeTitle(newVal)
    //   }
    // },
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions([
      "getLawRegulationsList",
      "getJudicialCaseList",
      "getDictionaryList",
    ]),
    changeTitle(title) {
      this.title = title;
    },
    handleSearchInfoTypeChange() {
      const newVal = this.searchInfo.type;
      this.searchInfo.country = "";
      if (newVal === "LAWS") {
        this.pageInfo.size = 8;
        this.title = "01d50dd";
        this.dataList = [
          {
            name: "e83c923",
            key: "category",
          },
          {
            name: "legalQuery.leftNavBar[0]",
            key: "releaseDepartment",
          },
          {
            name: "ebd8120",
            key: "effectiveness",
          },
          {
            name: "legalQuery.leftNavBar[1]",
            key: "regulationCategory",
          },
          {
            name: "legalQuery.leftNavBar[2]",
            key: "year",
          },
        ];
      } else {
        this.pageInfo.size = 5;
        this.title = "4bb20cd";
        this.dataList = [
          {
            name: "4bb20cd",
            key: "docType",
          },
          {
            name: "35e8162",
            key: "caseReason",
          },
          {
            name: "28c6e44",
            key: "trialDate",
          },
        ];
      }
      API.common
        .getDictionaryList({
          type: newVal === "CASE" ? "JUDICIAL_CASE" : "LAW_REGULATIONS",
        })
        .then((res) => {
          this.handleData(res);
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    handleData(newVal) {
      const list = newVal.content;
      // const obj = {
      //   中国: 'CHINA',
      //   俄罗斯: 'RUSSIA',
      //   哈萨克斯坦: 'KAZAKHSTAN',
      //   吉尔吉斯斯坦: 'KYRGYZSTAN',
      //   塔吉克斯坦: 'TAJIKISTAN',
      //   乌兹别克斯坦: 'UZBEKISTAN',
      //   巴基斯坦: 'PAKISTAN',
      //   印度: 'INDIA',
      // }
      // const r = list.map((item) => {
      //   return {
      //     value: obj[item.name],
      //     ...item,
      //   }
      // })
      this.countryList = list;
    },
    changeLabelData() {
      const query = this.$route.query;
      this.searchInfo.type = query.type ? query.type : "LAWS";
      this.searchInfo.country = query.country ? query.country : "";
      this.searchInfo.keyword = query.keyword ? query.keyword : "";
      this.handleSearchInfoTypeChange();
    },
    async translateKeyword() {
      let r = "";
      const country = this.searchInfo.country;
      const keyword = this.searchInfo.keyword;
      if (keyword) {
        const countryObj = {
          中国: "zh",
          俄罗斯: "ru",
          哈萨克斯坦: "ru",
          吉尔吉斯斯坦: "ru",
          塔吉克斯坦: "ru",
          乌兹别克斯坦: "ru",
          巴基斯坦: "urd",
          印度: "hi",
        };
        const { appid, secretKey, salt } = translateObj;
        const sign = this.$md5(`${appid}${keyword}${salt}${secretKey}`);
        const to = countryObj[country] || "zh";
        const from = "auto";
        const url = `/baiduApi/api/trans/vip/translate?q=${keyword}&from=${from}&to=${to}&appid=${appid}&salt=${salt}&sign=${sign}`;
        await API.common
          .getTranslateKeyword(url)
          .then((res) => {
            r = res.trans_result[0].dst;
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
      return r;
    },
    LAWS(data = {}) {
      const countryObj = {
        中国: "CHINA",
        俄罗斯: "RUSSIA",
        哈萨克斯坦: "KAZAKHSTAN",
        吉尔吉斯斯坦: "KYRGYZSTAN",
        塔吉克斯坦: "TAJIKISTAN",
        乌兹别克斯坦: "UZBEKISTAN",
        巴基斯坦: "PAKISTAN",
        印度: "INDIA",
      };
      const info = this.searchInfo;
      const obj = {
        type: info.type,
        category: info.category,
        country: countryObj[info.country],
        effectiveness: info.effectiveness,
        releaseDepartment: info.releaseDepartment,
        regulationCategory: info.regulationCategory,
        year: info.year,
        keyword: info.keyword,
        size: this.pageInfo.size,
        page: this.pageInfo.currentPage,
      };

      Object.assign(obj, data);
      this.getLawRegulationsList(obj);
    },
    CASE(data = {}) {
      const countryObj = {
        中国: "CHINA",
        俄罗斯: "RUSSIA",
        哈萨克斯坦: "KAZAKHSTAN",
        吉尔吉斯斯坦: "KYRGYZSTAN",
        塔吉克斯坦: "TAJIKISTAN",
        乌兹别克斯坦: "UZBEKISTAN",
        巴基斯坦: "PAKISTAN",
        印度: "INDIA",
      };
      const info = this.searchInfo;
      const obj = {
        docType: info.docType,
        keyword: info.keyword,
        country: countryObj[info.country],
        caseReason: info.caseReason,
        trialDate: info.trialDate,
        size: this.pageInfo.size,
        page: this.pageInfo.currentPage,
      };
      Object.assign(obj, data);
      this.getJudicialCaseList(obj);
    },
    async getData(data) {
      this.searchLoading = true;
      data = data || {};
      this.searchData = data;
      const keyword = await this.translateKeyword();
      console.log("xxx", keyword);
      if (keyword) {
        data = {
          ...data,
          keyword,
        };
      }
      const type = this.searchInfo.type;
      this[type](data);
    },
    changePage(val) {
      this.pageInfo.currentPage = val;
      this.getData(this.searchData);
    },
  },
};
</script>
<style scoped lang="less">
@import "~@/styles/variables.less";
.legal {
  width: @uni-width;
  min-height: calc(100vh - 534px);
  margin: 0 auto;
  .content {
    display: flex;
    justify-content: space-between;
    min-height: calc(100vh - 534px);
    margin-bottom: 20px;
    .lt {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 260px;
      height: auto;
      padding: 20px 0 20px 20px;
      background: #ffffff;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.03);
    }
    .rt {
      box-sizing: border-box;
      width: 920px;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
      .rtItmeContainer {
        width: 100%;
        height: auto;
      }
      .pagination {
        margin-top: 30px;
        margin-bottom: 40px;
      }
    }
  }
}
</style>
