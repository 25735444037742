<template>
  <div class="caseItme" @click="goDetails">
    <div class="item">
      <div class="title">
        {{ data.title }}
      </div>
      <div class="des">
        <div>{{ data.court }}</div>
        <div>{{ data.caseCode }}</div>
      </div>
      <div class="tag">【案件结果】</div>
      <div class="tagDes">
        {{ data.caseResult | textFilter }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CaseItme',
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {},
  created() {},
  methods: {
    handleTag() {
      let result = '失效/废止'
      if (this.data.effectiveness === '有效') {
        result = '现行有效'
      }
      return result
    },
    goDetails() {
      this.$router.push({
        path: '/legal/details',
        query: {
          id: this.data.id,
          type: this.type,
          country: this.country,
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.caseItme {
  .item {
    padding: 30px 30px 40px;
    &:hover {
      background: rgba(0, 164, 255, 0.04);
      .title {
        color: #00a4ff;
      }
    }
    .title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 28px;
    }
    .des {
      display: flex;
      margin-top: 20px;
      padding-bottom: 30px;
      font-size: 14px;
      color: #999999;
      line-height: 19px;
      border-bottom: 1px dashed rgba(0, 0, 0, 0.09);
      div {
        margin-right: 40px;
      }
    }
    .tag {
      margin: 29px 0 20px 0;
      font-size: 14px;
      color: #666666;
    }
    .tagDes {
      font-size: 14px;
      color: #999999;
      line-height: 28px;
    }
  }
}
</style>
